import { h } from 'preact';
import { Router } from 'preact-router';
import { Header } from './header';
import Home from '../routes/home';
import Mission from '../routes/mission';
import Vision from '../routes/vision';
import Bicycle from '../routes/bicycle';
import Ski from '../routes/ski';
import { Provider } from 'unistore/preact';
import createStore from 'unistore';

const store = createStore(
    {
        url: '/',
        name: '',
        dear: '',
        gender: '',
        sent: false,
        sentOnce: false,
        isThanks: false
    }
);

const App = () => (
    <Provider store={store}>
        <div class='Page'>
            <Header />
            <Router>
                <Home path='/' />
                <Mission path='/misija' />
                <Vision path='/vizija' />
                <Bicycle path='/dviratininkui' />
                <Ski path='/slidininkui' />
            </Router>
        </div>
    </Provider>
);

export default App;
