import { h } from 'preact';
import { Link } from 'preact-router/match';
import { connect } from 'unistore/preact';

export const Header = connect('sentOnce')(
    ({ sentOnce }) => (
        <nav class='navbar is-black' role='navigation' aria-label='main navigation'>
            <div class='navbar-brand'>
                <Link href='/' class='main navbar-item'>Nepiktai</Link>
                <Link
                    href='/vizija'
                    className={`navbar-item${sentOnce ? '' : ' padding-0 opacity-0'}`}
                    aria-label='Skaitykite daugiau apie viziją'
                    aria-hidden={sentOnce ? 'false' : 'true'}
                >
                    {sentOnce ? 'Vizija' : ''}
                </Link>
                <Link
                    href='/misija'
                    className={`navbar-item${sentOnce ? '' : ' padding-0 opacity-0'}`}
                    aria-label='Skaitykite daugiau apie misiją'
                    aria-hidden={sentOnce ? 'false' : 'true'}
                >
                    {sentOnce ? 'Misija' : ''}
                </Link>
                <Link
                    href='/dviratininkui'
                    className={'navbar-item'}
                    aria-label='dviratininkui'
                >
                    Dviratininkui
                </Link>
                <Link
                    href='/slidininkui'
                    className={'navbar-item'}
                    aria-label='slidininkui'
                >
                    Slidininkui
                </Link>
            </div>
        </nav>
    )
);
